import {Link} from 'gatsby';
import React from 'react';
import {FaFacebookSquare as FacebookIcon} from 'react-icons/fa';
import {BiMailSend as EmailIcon} from 'react-icons/bi';
import Icon from './icon';
import {cn} from '../lib/helpers';

import styles from './header.module.css';

const Header = ({onHideNav, onShowNav, showNav, siteTitle}) => (
  <div className={styles.root}>
    <div className={styles.wrapper}>
      <div className={styles.branding}>
        <Link to="/">{siteTitle}</Link>
      </div>

      <button
        type="button"
        className={styles.toggleNavButton}
        onClick={showNav ? onHideNav : onShowNav}>
        <Icon symbol="hamburger" />
      </button>

      <nav className={cn(styles.nav, showNav && styles.showNav)}>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/contact/">Contact</Link>
          </li>
          <li>
            <Link to="/history/">History</Link>
          </li>
          <li>
            <Link to="/orthodoxy/">Orthodoxy</Link>
          </li>
          <li>
            <Link to="/calendar/">Calendar</Link>
          </li>
          <li>
            <Link to="/donate/">Donate</Link>
          </li>
          <li>
            <a href="https://www.facebook.com/pg/SaintDemetriusChurch">
              <FacebookIcon className="icon-2" />
            </a>
          </li>
          <li>
            <a href="mailto:st.demetrius.akron@gmail.com">
              <EmailIcon className="icon" />
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
);

export default Header;
