import React from 'react';
import Header from './header';

import '../styles/layout.css';
import styles from './layout.module.css';

const Layout = ({children, onHideNav, onShowNav, showNav, siteTitle}) => (
  <>
    <Header
      siteTitle={siteTitle}
      onHideNav={onHideNav}
      onShowNav={onShowNav}
      showNav={showNav}
    />
    <div className={styles.content}>{children}</div>
    <footer className={styles.footer}>
      <div className={styles.footerWrapper}>
        <div className={styles.siteInfo}>
          <p>
            <b />
            St. Demetrius Serbian Orthodox Church
            <br />
            3106 Ridgewood Rd
            <br />
            Akron, OH 44333
            <br />
            <br />
            <a href="tel:330-666-7852">330-666-7852</a>
          </p>
          <p>
            &copy; {new Date().getFullYear()} St. Demetrius Serbian Orthodox
            Church
          </p>
        </div>
      </div>
    </footer>
  </>
);

export default Layout;
